import {Injectable} from '@angular/core';
import {NavigationEnd} from '@angular/router';
import {APP_YANDEX_METRIC_ID} from '../constants/yandex-metric.constant';

declare let ym: any;

@Injectable({
  providedIn: 'root',
})
export class YandexMetricService {
  sendBrowsingData(event: NavigationEnd): void {
    ym(APP_YANDEX_METRIC_ID, 'hit', event.urlAfterRedirects);
  }

  sendUserData(user: {email: string; name: string}): void {
    ym(APP_YANDEX_METRIC_ID, 'userParams', {
      Name: user.name,
      Email: user.email,
    });
  }

  reachGoal(name: string): void {
    ym(APP_YANDEX_METRIC_ID, 'reachGoal', name);
  }
}
