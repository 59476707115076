import {Component} from '@angular/core';
import {environment} from '@env/environment';
import {Select} from '@ngxs/store';
import {PikAuthService} from 'pik-header';
import {iif, Observable, of} from 'rxjs';
import {filter, map, switchMap} from 'rxjs/operators';

import {FullscreenState} from './core/store/fullscreen.state';
import {YandexMetricService} from './core/services/yandex-metric.service';
import {NavigationEnd, Router} from '@angular/router';
import {APP_YANDEX_METRIC_SCRIPT} from './core/constants/yandex-metric.constant';

interface UserData {
  email: string;
  name: string;
}

@Component({
  selector: 'sgp-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  @Select(FullscreenState.fullscreenMode) fullscreenMode$: Observable<boolean>;
  environment = environment;

  constructor(
    private readonly auth: PikAuthService,
    private readonly router: Router,
    private readonly $yandexMetric: YandexMetricService,
  ) {
    this.auth.authCallbackIfRequired();

    this.auth.isAuthenticated$
      .pipe(
        switchMap(loggedIn =>
          iif(
            () => !!loggedIn,
            this.auth.userData$.pipe(map((userData: UserData) => userData)),
            of(null),
          ),
        ),
      )
      .subscribe(userData => {
        if (!userData) {
          return;
        }

        this.addYandexMetric();
        this.sbsRouter(userData);
      });
  }

  private addYandexMetric() {
    const script = document.createElement('script');

    script.type = 'text/javascript';
    script.async = true;
    script.innerHTML = APP_YANDEX_METRIC_SCRIPT;

    document.head.appendChild(script);
  }

  private checkYaMetrics(event: NavigationEnd, user: UserData): void {
    this.$yandexMetric.sendBrowsingData(event);
    this.$yandexMetric.sendUserData(user);
  }

  private sbsRouter(user: UserData): void {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(event => this.checkYaMetrics(event as NavigationEnd, user));
  }
}
