import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule, Title} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SidebarModule} from '@app/modules/ui/sidebar/sidebar.module';
import {SIDEBAR_PROVIDER} from '@app/modules/ui/sidebar/sidebar.service';
import {CoreModule} from '@core/core.module';
import {environment} from '@env/environment';
import {TuiDialogHostModule} from '@taiga-ui/cdk';
import {
  TuiDialogModule,
  TuiAlertModule,
  TuiRootModule,
  TuiScrollbarModule,
  TuiScrollControlsModule,
} from '@taiga-ui/core';
import {SharedModule} from '@shared/shared.module';
import {NgDompurifySanitizer} from '@tinkoff/ng-dompurify';
import {GAConfig, PikAnalyticsModule} from 'pik-header';
import {Observable, of} from 'rxjs';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app.routing';
import {NotFoundModule} from './modules/not-found/not-found.module';
import {TUI_LANGUAGE, TUI_RUSSIAN_LANGUAGE} from '@taiga-ui/i18n';
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    AppRoutingModule,
    PikAnalyticsModule.forRoot(new GAConfig()),
    TuiRootModule,
    TuiScrollbarModule,
    TuiAlertModule,
    TuiScrollControlsModule,
    TuiDialogModule,
    TuiDialogHostModule,
    SidebarModule,
    NotFoundModule,
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'ru-RU'},
    Title,
    SIDEBAR_PROVIDER,
    NgDompurifySanitizer,
    {
      provide: TUI_LANGUAGE,
      useValue: of(TUI_RUSSIAN_LANGUAGE),
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
